
// This is a temporary generated file. Changes to this file will be overwritten eventually!
import React from "react"
import styled from "styled-components"

import SEO from "../src/components/SEO"
import InviewWrapper from "../src/elements/inviewWrapper";
import PageHeaderImage from "../src/components/pageHeaderImage";
import PageIntroText from "../src/components/pageHeaderIntro";

// Sections
import ImageTextGrid from '../src/layouts/ImageTextGrid';

const Page = ({ pageContext }) => {
  const {
    page: { title, pageBuilder, default_page_header: pageHeader },
  } = pageContext

  const layouts = pageBuilder.modules || []

  return (
    <>
      <SEO title={title}/>
			<PageHeaderImage content={pageHeader} title={title} />
			<InviewWrapper>
			  <PageIntroText content={pageHeader} />
	      {
	        layouts.map((layout, index) => {
	          
	              if (layout.fieldGroupName === 'page_Pagebuilder_Modules_ImageTextGrid') {
	                  return <ImageTextGrid {...layout} key={index} />;
	              }
	            
	        })
	      }
			</InviewWrapper>

    </>
  )
}

export default Page
  