import React from 'react'
import styled from 'styled-components'

import { breakpoint } from '../../shared/theme'
import { Content } from '../../elements/wyswyg'

import H3 from '../../elements/typography/h3'

const ImageTextCard = styled.div`
	grid-column: -1 / 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media ${breakpoint('tablet')} {
		grid-column: span 6;
		height: 100%;
	}
`

const CardInner = styled(Content)`
	margin-bottom: 24px;

	@media ${breakpoint('tablet')} {
		margin-bottom: 64px;
	}
`

const ImageWrapper = styled.div`
	position: relative;

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 0;
		padding-top: 50%;
	}
`

const CardImage = styled.img``

const CardTitle = styled(H3)`
	font-weight: 700;
`

const CardCopy = styled.div`
	> *:last-child
	{
		margin-bottom: 0;
	}
`

const index = ({ content }) => {
	return (
		<ImageTextCard>
			<CardInner>
				<CardTitle>{content.title}</CardTitle>
				<CardCopy dangerouslySetInnerHTML={{ __html: content.copy }} />
			</CardInner>
			{content.image && (
				<ImageWrapper>
					<CardImage
						src={content.image.mediaItemUrl}
						alt={'Placeholder'}
						className="bg-cover"
					/>
				</ImageWrapper>
			)}
		</ImageTextCard>
	)
}

export default index
