import React from 'react'
import styled from 'styled-components'
import { breakpoint, getColor } from '../../shared/theme'

import Grid from '../../elements/grid'
import SectionTransition from '../../elements/activeSectionDefault'
import InnerWrapper from '../../elements/innerWrapper'
import ImageTextCard from '../../components/imageTextCard'

const Wrapper = styled(SectionTransition)`
	color: ${(props) =>
		props.colorTheme === 'dark' ? getColor('white') : getColor('black')};
	background: ${(props) =>
		props.colorTheme === 'dark' ? getColor('black') : getColor('white')};
	padding: 48px 0 64px;

	@media ${breakpoint('tablet')} {
		padding: ${(props) => (props.spacing == 'large' ? '200px 0' : '136px 0')};
	}
`

const WrapperInner = styled.div`
	grid-column: -1 / 1;
	display: grid;
	grid-template-columns: repeat(12, minmax(0, 1fr));
	column-gap: 16px;
	grid-row-gap: 48px;
	align-items: start;

	@media ${breakpoint('tablet')} {
		grid-column: 2 / 14;
		grid-row-gap: 148px;
		column-gap: 40px;
	}

	@media ${breakpoint('desktop')} {
		grid-column: 3 / 13;
	}
`

const ImageTextGrid = ({ items, isActive }) => {
	if (!items || items === undefined || items.length == 0) {
		return null
	}

	return (
		<Wrapper colorTheme={'light'} isActive={isActive} className="module">
			<InnerWrapper>
				<Grid>
					<WrapperInner>
						{items.map((item, index) => (
							<ImageTextCard content={item} key={index} />
						))}
					</WrapperInner>
				</Grid>
			</InnerWrapper>
		</Wrapper>
	)
}

export default ImageTextGrid
